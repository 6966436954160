* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
